@page {
    size: A4 portrait;
}
.layout {
    min-height: 100vh;
    background: #ffffff;
    // background: linear-gradient(45deg, #ff7900 0%, #333 100%);
}
#languageSelector div {
    text-align: left;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    &.show {
        opacity: 10;
    }
}
.layout {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // min-height: calc(100vh - 100px);
    min-height: 100vh;
}
.primary-text {
    color: $primary-color;
}
.secondary-text {
    color: $secondary-text;
}
.active-claim-number {
    padding: 2px 5px;
    border-radius: 5px;
    color: #eab000;
    background-color: $primary-text;
}
.contact-link {
    color: $primary-color;
}

.user-select-none {
    user-select: none;
}
.common__page-title {
    text-align: center;
}
.mdi-icon {
    color: $mobile_insurance-color;
    margin: 0.5rem;
}
.cursor-pointer {
    cursor: pointer;
}

img {
    max-width: 100%;
}
